.videoTitle {
  max-width: 600px;

  @screen md {
    max-width: 780px;
  }
}

.ctasTitle {
  text-align: center;
  max-width: 380px;
  @screen md {
    max-width: 440px;
  }
}

.cta1Body {
  @apply max-w-[320px] md:max-w-none;
}

.cta2Body {
  max-width: 377px;
}

.cta1Body {
  max-width: 359px;
}

.cta1ImgContainer {
  max-width: 463px;
}

.cta2ImgContainer {
  max-width: 482px;
}

.mTop {
  /* We add this so this is not visible from the mobile menu */
  margin-top: 11px;
}

.belongXLogo {
  width: 161px !important;
  height: 44px !important;
}

.belongProLogo {
  width: 226px !important;
  height: 44px !important;
}

.title {
  @apply max-w-[824px] xl:max-w-[865px];
}
