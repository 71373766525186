.headerContainer {
  @apply h-[686px] lg:h-[580px] !important;
}

.subtitle {
  max-width: 760px;
  text-align: center;
}

.bgElementsContainer {
  height: 900px;
}

/* vvv BG ELEMENTS vvv */
.cloud1 {
  top: 220px;
  left: -32px;
  width: 108px;
  @screen lg {
    top: -50px;
    left: -890px;
    width: 417px;
  }
}

.cloud2 {
  top: 305px;
  right: 48px;
  width: 53px;
  @screen lg {
    top: -20px;
    left: -567px;
    width: 216px;
  }
}

.cloud3 {
  display: none;
  @screen lg {
    display: unset;
    top: 10px;
    left: 380px;
    width: 312px;
  }
}

.cloud4 {
  top: 195px;
  right: -19px;
  width: 108px;
  @screen lg {
    top: -60px;
    left: 581px;
    width: 308px;
  }
}

.balloon1 {
  display: none;
  @screen lg {
    display: unset;
    top: 110px;
    left: -532px;
    width: 63px;
    animation: ballon1BalanceY infinite 5s ease-in-out;
  }
}

.balloon2 {
  top: 250px;
  right: 135px;
  width: 70px;
  animation: ballon2BalanceY infinite 6s ease-in-out;
  @screen lg {
    top: 240px;
    left: 420px;
    width: 50px;
  }
}

.plane {
  display: none;
  @screen lg {
    display: unset;
    top: 120px;
    left: 540px;
    width: 70px;
    transform: rotate(-6deg) scaleX(-1);
  }
}

@keyframes ballon1BalanceY {
  from,
  to {
    transform: translateY(-10px) rotate(-15deg);
  }
  50% {
    transform: translateY(10px) rotate(-15deg);
  }
}

@keyframes ballon2BalanceY {
  from,
  to {
    transform: translateY(-8px) rotate(50deg);
  }
  50% {
    transform: translateY(8px) rotate(50deg);
  }
}
